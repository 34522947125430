export default {
    black: '#303030',
    white: '#FFFFFF',
    primary: '#2C5B8A',
    textPrimary: '#202225',
    textSecondary: '#747576',

    blackBackgroundHover: '#474848',
    blackBackgroundFocus: '#4F5051',
    blackBackgroundActive: '#575859',

    blue60: '#2972C9',
    blueLight: '#85C4E2',
    fieldRequiredYellow: '#FFFFCC',
    orange: '#F8972B',
    orangeDark: '#EE9129',
    redDark: '#BB0000',

    purpleDark: '#5E35B1',
    purpleLightest: '#EDE7F6',

    lightLinkDefault: '#C3CDEE',
    linkLightBlue: '#A3B5E5',

    // NOTE: Colors and their uses are still TBD - These may change to something more generic.
    primaryBackgroundColorActive: '#406A95',
    primaryBackgroundColorFocus: '#50779F',

    whiteBackgroundColorActive: '#EEF2F6',
    whiteBackgroundColorFocus: '#E6EBF1',

    primary60: '#4F7095',
    primary95: '#E2EAF9',
    primary99: '#F3F6FC',

    neutral10: '#001940',
    neutral30: '#555E68',
    neutral50: '#858C93',
    neutral60: '#9FA5AC',
    neutral70: '#B7BCC1',
    neutral80: '#CFD2D5',
    neutral90: '#E7E9EA',
    neutral95: '#EDEFF1',
    neutral99: '#F7F8FA',

    lightSecondaryDefault: '#DFE3E6',
    lightSecondaryFocus: '#D4DBE0',

    hoverBackground: '#DEE3E9',
    disabledDarkGrey: '#A6A6A7',
    disabledLightGrey: '#DBDDDE',
    blackDark: '#414141',

    primaryBorderColorFocus: '#0A88C5',
};
