import {
    createRoutesFromElements,
    Route,
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import ErrorPage from './views/ErrorPage';
import PageNotFoundPage from './views/PageNotFoundPage';
import AuthenticatedContainer from './AuthenticatedContainer';
import UnhandledExceptionErrorBoundary from './UnhandledExceptionErrorBoundary';
import MobileFriendlyContainer from './MobileFriendlyContainer';
import LogsPageProvider from './components/LogsPage/LogsPageProvider';
import ConfidentialFooterContainer from './ConfidentialFooterContainer';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<UnhandledExceptionErrorBoundary />} errorElement={<ErrorPage />}>
            <Route element={<AuthenticatedContainer />}>
                <Route element={<MobileFriendlyContainer />}>
                    <Route element={<ConfidentialFooterContainer />}>
                        <Route
                            path="/"
                            lazy={() =>
                                import(/* webpackChunkName: 'HomePage' */ './views/HomePage')
                            }
                        />
                        <Route
                            path="/families"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'FamiliesListPage' */ './views/families/FamiliesListPage'
                                )
                            }
                        />
                        <Route
                            path="/platforms/:familyId"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'PlatformFamilyPageContainer' */ './views/platforms/PlatformFamilyPageContainer'
                                )
                            }
                        >
                            <Route
                                path=""
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'PlatformFamily' */ './views/platforms/PlatformFamilyPage'
                                    )
                                }
                            />
                            <Route
                                path="instances"
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'PlatformFamilyInstancesPage' */ './views/platforms/instances/PlatformFamilyInstancesPage'
                                    )
                                }
                            />
                            <Route
                                path="instances/:instanceId"
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'PlatformFamilyInstancePage' */ './views/platforms/instances/PlatformFamilyInstancePage'
                                    )
                                }
                            />
                        </Route>
                        <Route
                            path="/families/:familyId"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'FamilyPageContainer' */ './views/families/FamilyPageContainer'
                                )
                            }
                        >
                            <Route
                                path=""
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'FamilyPage' */ './views/families/FamilyPage'
                                    )
                                }
                            />
                            <Route
                                path="instances"
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'FamilyInstancesPage' */ './views/families/instances/FamilyInstancesPage'
                                    )
                                }
                            />
                            <Route
                                path="instances/:instanceId"
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'InstancePage' */ './views/families/instances/InstancePage'
                                    )
                                }
                            />
                        </Route>
                        <Route
                            path="/customers/:customerId"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'CustomerPage' */ './views/customers/CustomerPage'
                                )
                            }
                        />
                        <Route
                            path="/customers/:customerId/families/:familyId"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'CustomerFamilyPageContainer' */ './views/customers/families/CustomerFamilyPageContainer'
                                )
                            }
                        >
                            <Route
                                path=""
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'CustomerFamilyPage' */ './views/customers/families/CustomerFamilyPage'
                                    )
                                }
                            />
                            <Route
                                path="instances"
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'CustomerFamilyInstancesPage' */ './views/customers/families/instances/CustomerFamilyInstancesPage'
                                    )
                                }
                            />
                        </Route>
                        <Route
                            path="/customers/:customerId/instances/:instanceId"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'CustomerInstancePage' */ './views/customers/CustomerInstancePage'
                                )
                            }
                        />
                        <Route
                            path="/search"
                            lazy={() =>
                                import(/* webpackChunkName: 'SearchPage' */ './views/SearchPage')
                            }
                        />
                        <Route path="/help">
                            <Route
                                path=""
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'HelpPage' */ './views/help/HelpPage'
                                    )
                                }
                            />
                            <Route
                                path="stat-descriptions"
                                lazy={() =>
                                    import(
                                        /* webpackChunkName: 'HelpStatDescriptionsPage' */ './views/help/HelpStatDescriptionsPage'
                                    )
                                }
                            />
                        </Route>
                        <Route
                            path="/reorder"
                            lazy={() =>
                                import(/* webpackChunkName: 'ReorderPage' */ './views/ReorderPage')
                            }
                        />
                    </Route>
                    <Route
                        path="/admin"
                        lazy={() =>
                            import(
                                /* webpackChunkName: 'AdminPageContainer' */ './views/admin/AdminPageContainer'
                            )
                        }
                    >
                        <Route
                            path=""
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'AdminLandingPage' */ './views/admin/AdminLandingPage'
                                )
                            }
                        />
                        <Route
                            path="customers"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'ManageCustomersPage' */ './views/admin/ManageCustomersPage'
                                )
                            }
                        />
                        <Route
                            path="families"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'ManageFamiliesPage' */ './views/admin/ManageFamiliesPage'
                                )
                            }
                        />
                        <Route
                            path="families/:familyId"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'ManageFamilyPage' */ './views/admin/ManageFamilyPage'
                                )
                            }
                        />
                        <Route
                            path="families/:familyId/applications/:applicationKey"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'ManageFamilyApplicationPage' */ './views/admin/ManageFamilyApplicationPage'
                                )
                            }
                        />
                        <Route
                            path="families/:familyId/applications/:applicationKey/stat_metadata/:statKey"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'ManageFamilyStatMetadataItemPage' */ './views/admin/ManageFamilyStatMetadataItemPage'
                                )
                            }
                        />
                        <Route
                            path="pending-customers"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'PendingCustomersPage' */ './views/admin/PendingCustomersPage'
                                )
                            }
                        />
                        <Route
                            path="sql-query"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'SqlQueryPage' */ './views/admin/SqlQueryPage'
                                )
                            }
                        />
                        <Route
                            path="users"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'ManageUsers' */ './views/admin/ManageUsersPage'
                                )
                            }
                        />
                    </Route>
                </Route>
                <Route
                    element={
                        <LogsPageProvider>
                            <MobileFriendlyContainer />
                        </LogsPageProvider>
                    }
                >
                    <Route element={<ConfidentialFooterContainer />}>
                        <Route
                            path="/logs"
                            lazy={() =>
                                import(/* webpackChunkName: 'LogsPage' */ './views/LogsPage')
                            }
                        />
                        <Route
                            path="/users/:userName"
                            lazy={() =>
                                import(
                                    /* webpackChunkName: 'UserProfilePage' */ './views/UserProfilePage'
                                )
                            }
                        />
                    </Route>
                </Route>
            </Route>
            <Route
                path="/login"
                lazy={() => import(/* webpackChunkName: 'LoginPage' */ './views/LoginPage')}
            />
            <Route path="/error" element={<ErrorPage />} />,
            <Route path="*" element={<PageNotFoundPage />} />
        </Route>,
    ),
);

export default function App() {
    return <RouterProvider router={router} />;
}
